import React from 'react'

const Construccion = () => {
    return (
        <div className="bloque-construccion">
            <h1>
                <span className="definicion">const</span> <span className="funcion">revisarPortafolio</span>
                <span className="asignacion">=</span>
                <span className="parametro">contenido</span> <span className="flecha">=></span> <span
                    className="parentesis">(</span>
                <span className="string">"En construcción"</span> <span className="parentesis">)</span>
            </h1>
        </div>
    )
}

export default Construccion