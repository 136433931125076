import Construccion from "./components/Construccion";


function App() {
  return (
    <div className="app">
      <Construccion />
    </div>
  );
}

export default App;
